<template>
  <div class="wrapper">
    <span class="icon" v-for="(item, index) in grade" :key='index' :class="item"></span>
    <span class="percent">{{percent}}%</span>
  </div>
</template>
<script>

  export default {
    props:[
      'percent'
    ],
    data(){
      return{
      }
    },
    computed: {
      grade(){
        if(this.percent){
          const int = Math.floor(this.percent / 20)
          const arr = new Array(int).fill('icon-full')
          const rest = 100 - this.percent
          const empty = Math.floor(rest / 20)
          const arr1 = new Array(empty).fill('icon-null')
          const rem = rest % 20
          if(rem !== 0){
            arr.push('icon-half')
          }
          arr.push(...arr1)
          return arr
        }
      }
    },
    mounted(){
    }
  }
</script>
<style scoped lang="scss">
  .wrapper{
    font-size: 0;
   .icon{
     display: inline-block;
     width: 39px;
     height: 39px;
     background-size: 100%;
     vertical-align: text-bottom;
   }
    .icon-full{
      background-image: url("~assets/movie/img/full.png");
    }
    .icon-half{
      background-image: url("~assets/movie/img/half.png");
    }
    .icon-null{
      background-image: url("~assets/movie/img/null.png");
    }
    .percent{
      display: inline-block;
      vertical-align: text-bottom;
      font-weight: bold;
      font-size: 36px;
      color: #70767D;
    }
  }
</style>
