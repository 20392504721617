<template>
  <div class="wrapper index-wrapper">
    <!-- You can find this swiper instance object in current component by the "mySwiper"  -->
    <section class="banner-wrapper">
      <div v-swiper:mySwiper="swiperOption" class="my-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(banner, index) in banners" :key='index'>
            <a class="banner-link" :href="banner.path">
              <img class="banner-img" :src="banner.review_cover" :alt="banner.review_name" @error="imgError">
              <div class="banner-introduce">
                <div class="movie-content">{{banner.review_title}}</div>
                <div class="movie-name"><img class="review-author" :src="banner.review_author_avatar" :alt="banner.review_author">评《{{banner.review_name}}》</div>
              </div>
            </a>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination-bullets"></div>
      </div>
    </section>
    <section class="content">

      <div class="subtitle">
        <h2 class="subtitle-title">影院热映</h2>
        <nuxt-link class="subtitle-more" to="/movie/reying">更多</nuxt-link>
      </div>
      <swiper :items="reying"></swiper>

      <div class="subtitle">
        <h2 class="subtitle-title">热门电影</h2>
        <nuxt-link class="subtitle-more" to="/movie/remen">更多</nuxt-link>
      </div>
      <swiper :items="remen"></swiper>

      <div class="footer">
        <h2 class="subtitle">分类片源</h2>
        <div class="footer-item">
          <nuxt-link class="footer-item-link" to="/movie/dongzuo" ><span>动作电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/xuanyi" ><span>悬疑电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/xiju" ><span>喜剧电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/kehuan" ><span>科幻电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/guzhuang" ><span>古装电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/aiqing" ><span>爱情电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/donghua" ><span>动画电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/zhanzheng" ><span>战争电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/fanzui" ><span>犯罪电影</span></nuxt-link>
          <nuxt-link class="footer-item-link" to="/movie/kongbu" ><span>恐怖电影</span></nuxt-link>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
  import swiper from "~/components/movie/swiper"
  import envObj from '~/assets/movie/js/env'
  import {request} from '~/components/api/movie/axios'

  let baseUrl = envObj.baseUrl
  let movieUrl = envObj.movieUrl
  export default {
    props: [
      'banners'
    ],
    components:{
      swiper
    },
    data () {
      return {
        notNextTick: true,
        swiperOption: {
          autoplay: {
            disableOnInteraction: false
          },
          // loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        loadingJpg: require('@/static/movie/loading.jpg')
      }
    },
    async asyncData ({ app, route, redirect }) {

      let result = await Promise.all([
        request({ url: `${baseUrl}/video2/filter`, method: 'post', data: {order: ['reying']}, cache: true }),
        request({ url: `${baseUrl}/video2/filter`, method: 'post', data: {order: ['remen']}, cache: true })
      ]).catch( e => {
        redirect(302, '/movie/error', { 'errorfrom': route.fullPath })
      })
      if(!result){
        return
      }

      let dataObj = {}

      // 处理热映数据
      let reyingData = result[0]
      if(reyingData){
        reyingData.data.forEach(item => {
          item.link = `${movieUrl}/movies/${item.id}?entrypage=xl_mobile_brandsite_movie_homepage&entry=xl_mobile_movie_home_movie_card`
          item.percent = Math.round(item.movie_score.popcorn_index*100)
          item.vertical_cover_url = item.vertical_cover_url+ '?imageView2/1/w/297/h/396'
        })
      }
      dataObj.reying = reyingData.data

      //处理热门数据
      let remenData = result[1]
      if(remenData){
        remenData.data.forEach(item => {
          item.link = `${movieUrl}/movies/${item.id}?entrypage=xl_mobile_brandsite_movie_homepage&entry=xl_mobile_movie_home_movie_card`
          item.percent = Math.round(item.movie_score.popcorn_index*100)
          item.vertical_cover_url = item.vertical_cover_url+ '?imageView2/1/w/297/h/396'
        })
      }
      dataObj.remen = remenData.data

      return dataObj
    },
    methods: {
      imgError (event) {
        event.target.src = this.loadingJpg
      }
    }
  }
</script>



<style lang="scss">
  .index-wrapper{
    margin-top: 180px;
  }
  .banner-wrapper{
    display: inline-block;
    width: 1080px;
    overflow: hidden;
    .my-swiper{
      width: 990px;
      margin: 0 auto;
      padding-left: 3px;
      .swiper-wrapper {
        width: 990px;
        height: 558px;
        margin-left: 6px;
        padding: 30px 0 60px;
      }
      .swiper-slide{
        width: 990px;
        height: 558px;
        margin-right: 15px;
        overflow: hidden;
        .banner-link{
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        .banner-img{
          width: 100%;
          height: 100%;
          background-color: #f0f1f2;
          border-radius: 20px;
        }
        .banner-introduce{
          position: absolute;
          width: 900px;
          left: 50%;
          transform: translateX(-50%);
          bottom: 30px;
          color: #fff;
          .movie-name{
            font-size: 33px;
            margin-top: 13px;
            .review-author{
              width: 60px;
              height: 60px;
              border-radius: 50%;
              vertical-align: middle;
            }
          }
        }
      }

      .swiper-pagination-bullets{
        bottom: 0px;
      }
      .swiper-pagination{
        font-size: 0;
        line-height: 12px;
      }
      .swiper-pagination-bullet{
        width: 12px;
        height: 12px;
        background-color: #D0D1D4;
        transition: all .4s ease;
        opacity: 1;
        /*transform: scale(.5);*/
      }
      .swiper-pagination-bullet-active{
        width: 60px;
        background: linear-gradient(to right, #38d0ff, #1aa3ff);
        border-radius: 100px;
      }
      .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
        margin: 0;
      }
      .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active{
        margin: 0 -16px;
      }
    }
  }
  .content{
    .subtitle{
      font-size: 45px;
      color: #262A2F;
      font-weight: bold;
      margin: 36px 45px;
      .subtitle-title{
        font-size: 45px;
        display: inline-block;
      }
      .subtitle-more{
        float: right;
        font-size: 36px;
        line-height: 48px;
        color: #70767D;
        &::after{
          content: '';
          display: inline-block;
          background-image: url("~assets/movie/img/normal@3x.png");
          background-size: 100% 100%;
          width: 48px;
          height: 48px;
          text-align: center;
          margin-left: 15px;
          vertical-align: text-bottom;
        }
      }
    }
    .footer{
      margin: 10px 0;
      .footer-item{
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .footer-item-link{
          display: inline-block;
          width: 216px;
          height: 156px;
          line-height: 156px;
          text-align: center;
          font-size: 45px;
          color: #4677BC;
          span{
            display: inline-block;
            white-space: nowrap;
            width: 90px;
            overflow: hidden;
          }
        }
      }
    }
  }
</style>
