<template>
   <div v-swiper:mySwiper="swiperOption" class="swiper-container">
    <div class="swiper-wrapper">
      <div v-for="(item, index) in items" :key='index' class="swiper-slide">
        <a class="hot-item-link" :href="item.link">
          <img v-if="index<4" class="hot-item-img" :src="item.vertical_cover_url" :alt="item.name" @error="imgError">
          <img v-else class="hot-item-img" src="" v-lazy="item.vertical_cover_url" :alt="item.name" @error="imgError">
          <div class="hot-item-name">{{item.name}}</div>
          <grade :percent="item.percent"></grade>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import grade from './grade.vue'
export default {
  components: {
    grade
  },
  props:[
    'items'
  ],
  data(){
    return{
      swiperOption:{
        slidesPerView: 'auto',
      },
      loadingJpg: require('@/static/movie/loading.jpg')
    }
  },
  methods: {
    imgError (event) {
      event.target.src = this.loadingJpg
    }
  },
  mounted(){
  }
}
</script>
<style scoped lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 26px;
}
.swiper-wrapper{
  padding-left: 45px;
}
.swiper-slide {
  width: 297px;
  height: 537px;
  text-align: left;
  font-size: 45px;
  background: #fff;
  margin-right: 30px;

    /* Center slide text vertically */
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .hot-item-link{
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .hot-item-img{
    width: 297px;
    height: 396px;
    background-color: #f0f1f2;
    border-radius: 20px;
  }
  .hot-item-name{
    font-size: 45px;
    color: #262A2F;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hot-item-percent{
    font-size: 12px;
  }
}
</style>
